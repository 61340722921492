import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { ExternalLink, EmailLink } from "../components/sitelinks";
import {
  MainContainer,
  BoxContainer,
  BoxHeading,
  SubHeading,
  BoxCopy
} from "../components/simplepagelayout";

const SoftwarePage = () => (
  <Layout>
    <SEO title="Contact" />
    <MainContainer>
      <BoxContainer>
        <BoxHeading>
          Contact Notional Pipe
        </BoxHeading>
        <BoxCopy>
          <p>
            For Contour Rig Tools support, email us at <EmailLink href="mailto: support@notionalpipe.com">support@notionalpipe.com</EmailLink>.
          </p>
          <p>
            To purchase Contour Rig Tools, email us at <EmailLink href="mailto: sales@notionalpipe.com">sales@notionalpipe.com</EmailLink>.
          </p>
          <p>
            To inquire about other services, email us at <EmailLink href="mailto: business@notionalpipe.com">business@notionalpipe.com</EmailLink>.
          </p>
          <SubHeading>Social Media</SubHeading>
          <br />
          <p>
            Follow <ExternalLink href="https://www.youtube.com/channel/UCoTin4y_JsdGbK2imeiGTQA" target="_blank">our Youtube channel</ExternalLink>. <br />
            {/*We stream live every Tuesday at 4pm EST. We talk about using our tools, and other rigging and animation topics. <ExternalLink href="https://www.youtube.com/channel/UCoTin4y_JsdGbK2imeiGTQA" target="_blank">Come join us!</ExternalLink>*/}
          </p>
          <p>
            Follow <ExternalLink href="https://twitter.com/NotionalPipe" target="_blank">us on Twitter</ExternalLink>.
          </p>
          <p>
            Raf blogs about animation, and animation software, at his blog, <ExternalLink href="https://justtodosomethingbad.com" target="_blank">Just To Do Something Bad</ExternalLink>.
          </p>
        </BoxCopy>
      </BoxContainer>

    </MainContainer>
  </Layout >
);

export default SoftwarePage;
